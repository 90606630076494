// Styles file for `quicksolarquote.com`.

// Styles written here will override the corresponding component
// This is a stylesheet example of `Harpquiz`.

import { css, keyframes } from 'styled-components';
import images from './images';

// Theming
const DEFAULT_COLORS = {
  questionTitle: '#000',
  text: '#000',
  headline: '#000',
  link: '#337ab7',
  choiceInput: '',
  choiceInputHover: '#182B67',
  submitButtonBackground: '',
  submitButtonText: '',
  headerBackground: '',
  mainBackground: '',
  surveyBackground: '',
  footerBackground: '',
  progressBarBackground: '#f5f5f5',
  progressBarFill: '#337ab7',
  sliderFill: '#f5f5f5',
};

const DEFAULT_FONT = {
  questionTitle: '22px',
  text: '16px',
  headline: '28px',
  submitButton: '30px',
  tcpa: '11px',
  footerText: '12px',
};

const DEFAULT_SIZE = {
  headerHeight: '90px',
  headerMobileHeight: '',
  submitButtonWidth: '205px',
  inputFieldHeight: '40px',
  inputFieldWidth: '400px',
  inputFieldMobileWidth: '',
};

// Header
const HeaderContainer = css`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: ${DEFAULT_SIZE.headerHeight}; /* Don't forget to change the min-height calculation for SurveyBackground */
  width: 100vw;
  padding: 15px 20px;
  margin: auto;
  background: #182b67;
  @media (max-width: 801px) {
    justify-content: center;
  }
`;

const HeaderImage = css`
  height: auto;
  max-width: 60%;
  max-height: 100%;
  margin-bottom: 0;
  @media (max-width: 801px) {
    max-width: 80%;
  }
`;

// Only used when sites background color is needed
// specificy "background-color" because of the blurred-rates image.
const AppWrapper = css`
  // background-color: ${DEFAULT_COLORS.mainBackground} !important;
  min-height: 100vh;
`;

// Main Background
const SurveyBackground = css`
  min-height: calc(100vh - 92px);
  max-width: 960px;
  width: 100vw;
  margin: 70px auto 0;
  display: flex;
  background-color: ${DEFAULT_COLORS.surveyBackground};
  @media (max-width: 801px) {
    display: block;
    margin: 15px auto;
  }
`;

const SurveyContainer = css`
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: #fff;
  margin: 0 auto 20px;
  max-width: 960px;

  div {
    &#alternate-container {
      /* Used if you need to overwrite the width of the hidden container */
      /* width: 100%; */
    }
  }
`;

const QuestionNumber = css`
  font-size: 14px;
  width: 100%;
  max-width: ${DEFAULT_SIZE.inputFieldWidth};
  margin-bottom: 5px;
`;

const FormContainer = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  margin: 0 auto;
  width: 90%;
  padding: 10px 0;
`;

const GlobalSealImg = css`
  max-width: 110px;
`;

// Containers
// Multiple choice
const ChoiceContainer = css`
  display: flex;
  align-items: center;
  box-shadow: 1px 1px 4px 0 rgba(0, 0, 0, 0.1);
  padding: 10px 40px;
  margin: 8px 0;
  transition: background 230ms ease;
  width: 100%;
  max-width: ${DEFAULT_SIZE.inputFieldWidth};
  height: ${DEFAULT_SIZE.inputFieldHeight};
  background: ${DEFAULT_COLORS.choiceInput};
  color: ${DEFAULT_COLORS.text};
  cursor: pointer;
  border: 1px solid #ced4da;
  border-radius: 5px;
  justify-content: center;
  &:active {
    opacity: 0.9;
    background: ${DEFAULT_COLORS.choiceInputHover};
  }

  &:hover {
    opacity: 0.9;
    background: ${DEFAULT_COLORS.choiceInputHover};
    color: #fff;
    @media (max-width: 801px) {
      background: ${DEFAULT_COLORS.choiceInput};
      color: ${DEFAULT_COLORS.text};
    }
  }
`;

// Styles for selected radio button (multiple-choice)
const InputContainer = css`
  width: 100%;
  max-width: ${DEFAULT_SIZE.inputFieldWidth};

  > input:checked + label {
    background: ${DEFAULT_COLORS.choiceInputHover};
    color: #fff;
  }
  &.input-path-choice {
    width: 48%;
  }
`;

// Input
const inputStyle = css`
  width: 100%;
  max-width: ${DEFAULT_SIZE.inputFieldWidth};
  height: 50px;
  padding-left: 20px;
  border-radius: 3px;
  font-size: 16px;
  background: #f5f5f5;
  border: ${props =>
    props.border
      ? '1px solid red'
      : '1px solid #ced4da'} !important; /* overrides initial setting*/

  ::-webkit-input-placeholder {
    text-align: center;
  }
  text-align: center;
`;

const InputField = css`
  ${inputStyle}
  border: ${props => props.border};

  @media (max-width: 800px) {
    width: 100%;
  }
`;

const StyledTextArea = css`
  ${inputStyle}
  border-radius: 5px;
  border: ${props => props.border};
`;

const AddressContainer = css`
  display: flex;
  flex-direction: column;
  max-width: ${DEFAULT_SIZE.inputFieldWidth};
  width: 100%;

  p {
    margin-bottom: 0;
    margin-top: 10px;
  }

  input {
    width: 100%;
    max-width: ${DEFAULT_SIZE.inputFieldWidth};
    margin: auto;
  }
`;

// Dropdown
const StyledSelect = css`
  ${inputStyle}
  margin: 0;
  border: 1px inset #fff;
  -webkit-appearance: none;
  background: url(${images.Dropdown}) 95% 50% no-repeat #f5f5f5;
  background-size: 15px;
`;

// Zip input
const StyledZip = css`
  ${inputStyle}
  margin-bottom: 12px;
  color: #555;
  border: ${props =>
    props.border
      ? '1px solid red'
      : '1px solid #ced4da'} !important; /* overrides initial setting*/
  padding: 0 20px;
  text-align: center;

  ::-webkit-inner-spin-button,
  ::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;

const FinalInputContainer = css`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: ${DEFAULT_SIZE.inputFieldWidth};

  input,
  select {
    margin-top: 10px;
    margin-bottom: 5px;
    width: 100%;
    height: 40px;
  }
`;

// Hidden inputs
const HiddenContainer = css`
  display: flex;
  flex-direction: column;
  margin: 10px auto;
  width: 100%;
  margin-top: ${props => props.secondary && '0'};

  input {
    height: ${DEFAULT_SIZE.inputFieldHeight};
  }
`;

// Survey styles
const QuestionTitle = css`
  color: ${DEFAULT_COLORS.questionTitle};
  font-weight: 600;
  font-size: ${DEFAULT_FONT.questionTitle};
  line-height: 1;
  margin-bottom: 10px;
  text-align: center;
  width: 100%;

  @media (max-width: 600px) {
    font-size: 18px;
  }
`;

const InputTitle = css`
  margin-bottom: 0;
`;

const Subtitle = css`
  text-align: center;
  margin-bottom: 10px;
`;

const TipContainer = css`
  text-align: center;
  background: #fff;
  padding: 10px;
  color: #000;
  font-size: 15px;
  border-radius: 3px;
  width: 80%;
`;

const QuestionContainer = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  &.question-container-ElectricCompany {
    flex-direction: column-reverse;
  }
`;

const QuestionBodyContainer = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 10px;
  width: 100%;
  &.question-path-choice {
    flex-direction: row;
    justify-content: space-between;
  }
`;

// Slider
const StyledSlider = css`
  width: 100%;
`;

const StyledThumb = css`
  background: url(${images.SliderArrows}) 50% 50%/14px no-repeat #333;
  border: 1px solid #bdc3c7;
  width: 29px;
  height: 29px;
  border-radius: 50%;
  cursor: pointer;
  position: relative;
  z-index: 1;
  transform: scale(1.1);
`;

const DisplayNumber = css`
  font-size: ${({ primary }) => (primary ? '28px' : '14px')};
  font-weight: 500;
  margin-bottom: 0;
`;

const NumberContainer = css`
  display: flex;
  justify-content: space-between;
  width: inherit;
`;

const SliderContainer = css`
  width: inherit;
  height: 10px; /* Don't forget to change the height of "FillContainer" as well */
  border-radius: 8px;
  border: 1px solid #bdc3c7;
  background: #fff;
`;

const FillContainer = css`
  position: absolute;
  height: 10px;
  border-radius: 8px 0 0 8px;
  background: ${DEFAULT_COLORS.sliderFill};
  width: ${props => `${props.width}%`};
`;

// ProgressBar
const ProgressContainer = css`
  margin: 0 auto 10px auto;
  width: 50vw;
  max-width: 400px;
  height: 20px;
  background: ${DEFAULT_COLORS.progressBarBackground};
  border-radius: 5px;
  overflow: hidden;
`;

// Progressbar animation
const ProgressBarStripes = keyframes`
  from {
    background-position: 50px 0;
  } to {
    background-position: 0 0;
  }
`;

const StatusBar = css`
  animation: ${ProgressBarStripes} 2s linear infinite;
  background-image: linear-gradient(
    45deg,
    rgba(255, 255, 255, 0.15) 25%,
    transparent 25%,
    transparent 50%,
    rgba(255, 255, 255, 0.15) 50%,
    rgba(255, 255, 255, 0.15) 75%,
    transparent 75%,
    transparent
  );
  background-size: 50px 50px;
  width: ${props => props.width};
  height: inherit;
  background-color: ${DEFAULT_COLORS.progressBarFill};
  transition: width 0.25s ease;
`;

// Used for the footer progressbar.
const FooterNumberStatus = css`
  /* position: absolute;
  left: 50%;
  top: 15px;
  margin-left: -52px;
  color: #fff;
  font-weight: 300;

  @media (max-width: 800px) {
    display: none;
  } */
`;

// buttons
const SubmitButton = css`
  margin: 15px 0;
  width: 48%;
  padding: 7px 5px;
  border-radius: 3px;
  background-color: #85bc45;
  border: 0px;
  font-size: ${DEFAULT_FONT.submitButton};
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  transition: all 0.3s ease-in-out;
  cursor: pointer;
  .initial & {
    width: 100%;
    flex-direction: column;
    .nextButtonHelper {
      font-size: 14px;
      font-weight: 300;
      margin: 5px auto;
    }
  }

  &:hover {
    opacity: 0.9;
  }

  /* Final Submit button is different than the 'next' buttons */
  &#submit-button {
    width: 300px;
    margin: 0 auto;
  }
`;

const BackButton = css`
  border: none;
  background: #bfc1c4;
  color: #fff;
  cursor: pointer;
  width: 48%;
  margin: 15px 0;
  padding: 7px 5px;
  font-size: 30px;
  font-weight: 600;
  border-radius: 3px;
  &:hover {
    opacity: 0.8;
  }
  &#change-address {
    width: initial;
    background: transparent;
    font-size: initial;
  }
  &.last-page-previous-button {
    display: none;
  }

  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    background: none;
  }
`;

const ButtonContainer = css`
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  // max-width: ${DEFAULT_SIZE.submitButtonWidth};
  .initial & {
    flex-direction: column;
  }
`;

// Footer
const FooterContainer = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px 30px;
  background: #182b67;
  max-width: 960px;
  width: 100%;
  margin: auto;

  p {
    margin-bottom: 0;
    width: 100%;
    color: #fff;
  }

  a {
    color: ${DEFAULT_COLORS.link};
  }
`;

const DisclosureContainer = css`
  text-align: center;
  max-width: 960px;
  margin: 3vh auto;

  a {
    display: inline-block;
    margin-bottom: 20px;
    font-size: 12px;
  }
`;

const ModalButtonContainer = css`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  align-content: center;
  margin: 2vh auto;
  border-top: 1px solid #c9c9c9;
  padding-top: 1vh;

  span {
    color: ${DEFAULT_COLORS.link};
  }

  @media (max-width: 500px) {
    width: 98%;
  }
`;

const ModalButton = css`
  border: 0;
  background: inherit;
  color: ${DEFAULT_COLORS.link};
  cursor: pointer;

  &:hover {
    text-decoration: underline;
    color: #fff;
  }
`;

// Modal
const ModalBackground = css`
  position: fixed;
  display: flex;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  z-index: 20;
`;
const ModalContainer = css`
  margin: auto;
  background: white;
  width: ${props => (props.primary ? '50vw' : '70vw')};
  height: ${props => (props.primary ? '60vh' : '80vh')};
  padding: 3vh 3vw;
  border-radius: 10px;
  overflow: auto;

  @media (max-width: 900px) {
    width: 90vw;
  }
`;

const ExitButton = css`
  top: ${props => (props.primary ? '19vh' : '9vh')};
  right: ${props => (props.primary ? '24vw' : '14vw')};
  position: absolute;
  border-radius: 50%;
  border: 1px solid #eee;
  font-size: 1.5em;
  cursor: pointer;
  transition: all 0.5s ease;
  height: 33px;
  width: 33px;
  background: #fff;

  &:hover {
    background: #969696;
    color: #fff;
  }

  @media (max-width: 900px) {
    right: 3vw;
  }
`;

const ModalInformation = css`
  display: flex;
  flex-direction: column;
  /* overflow: scroll; */
  line-height: 1.5em;

  br {
    line-height: 2em;
  }

  p {
    width: 100%;
    color: #000;
  }

  @media (max-width: 600px) {
    font-size: 0.8em;
  }
`;

const BoldText = css`
  font-size: 11px;
  text-transform: uppercase;
  color: #fff;
  text-align: ${props => props.primary && 'center'};
`;

const Text = css`
  color: #585858;
  font-size: 14px;
  font-weight: ${props => props.primary && '700'};
  margin-bottom: 5px;
`;

const FooterText = css`
  margin-top: ${props => props.primary && '30px'};
  white-space: pre-line;
  text-align: center;
  font-size: ${DEFAULT_FONT.footerText};
  color: ${({ primary }) => primary && '#767676'};
`;

const ImageList = css`
  margin: 0;
  list-style-type: none;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
`;

const HeadlineContainer = css`
  text-align: center;
  margin: 0 auto;
  padding-right: 30px;
  .additionalHeadlineText {
    text-align: left;
    color: #fff;
    display: flex;
    margin-bottom: 10px;
    @media (max-width: 801px) {
      margin-bottom: 10px;
      padding-right: 0;
    }
  }
  .icon-checkmark {
    color: #7ec636;
    margin-right: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  @media (max-width: 801px) {
    padding: 0 20px;
    display: none;
    .main-app-page-0 & {
      display: block;
    }
  }
`;

const HeadlineHeader = css`
  color: #fff;
  font-size: 48px;
  font-weight: 500;
  margin-bottom: 10px;
  text-align: left;
  @media (max-width: 801px) {
    font-size: 22px;
    font-weight: 400;
  }
`;

const SubHeader = css`
  font-size: ${DEFAULT_FONT.text};
  font-weight: 200;
  color: ${DEFAULT_COLORS.headline};
  margin-bottom: 0;
`;

const SmallText = css`
  font-size: ${props => (props.secondary ? '0.9em' : '0.7em')};
  font-weight: ${props => (props.primary ? '700' : '200')};
  max-width: 500px;
  margin: auto;
`;

const ErrorText = css`
  color: #ee0000;
  font-style: italic;
  text-align: center;
  font-size: 12px;
`;

const FinalDisclosureContainer = css`
  padding: 20px;
  background-color: #fff;
  border-radius: 4px;
  min-height: 20px;
  max-width: 800px;
  margin: 0 auto;

  p,
  label {
    margin-bottom: 2px;
    font-size: ${DEFAULT_FONT.tcpa};
    color: #767676;
    line-height: 1.3;
  }
`;

// Loading Spinner
const Bounce = keyframes`
  0%, 80%, 100% { 
    transform: scale(0);
  } 40% { 
    transform: scale(1.0);
  }
`;

const SpinnerContainer = css`
  margin: 100px auto 0;
  width: 70px;
  text-align: center;
`;

const Spinner = css`
  width: 18px;
  height: 18px;
  background-color: #5598c1;
  border-radius: 100%;
  display: inline-block;
  animation: ${Bounce} 1.4s infinite ease-in-out both;
  animation-delay: ${props => props.delay};
`;

const LoadingContainer = css`
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.6);
  z-index: 2;
`;

const SpinnerMainContainer = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: auto;
  width: auto;
  padding: 40px;
  background: #fff;
`;

const CongratsHolder = css`
  /* Style property not used in this survey */
`;

const CheckboxWrapper = css`
  display: flex;
  flex-direction: column;
  margin-top: 1vh;
`;

const CheckboxContainer = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 5px 0;
`;

const CheckboxLabel = css`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;

  > input {
    -webkit-appearance: none;
    height: 0;
    width: 0;
    margin-left: 5px;
  }

  > input[type='checkbox']:before {
    content: '';
    display: block;
    position: absolute;
    width: 22px;
    height: 22px;
    top: 0;
    left: 0;
    border: 1px solid #dedede;
    border-radius: 3px;
    background-color: white;
    box-shadow: inset #777 0 0 2px;
    margin: 4px;
  }
  > input[type='checkbox']:checked:after {
    content: '';
    display: block;
    width: 6px;
    height: 12px;
    border: solid grey;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
    position: absolute;
    top: 3px;
    left: 9px;
    margin: 4px;
  }

  @media (max-width: 500px) {
    font-size: 0.8em;
  }
`;

const CheckboxParagraph = css`
  margin-left: 40px;
  margin-bottom: 0;
  font-size: 0.9em;
  text-align: left;
  font-style: italic;
`;

// Change Address container on final-frame step
const ChangeAddress = css`
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: ${DEFAULT_SIZE.inputFieldWidth};
  width: 100%;
  margin-top: 5px;

  p {
    margin-bottom: 0;
  }
  button {
    color: ${DEFAULT_COLORS.link};
    text-decoration: underline;
    margin: 0;
  }
`;

const AddressSubtext = css`
  width: 100%;
  margin-bottom: 5px;
`;

const LenderDropdown = css`
  background: #fff;
  margin-bottom: 10px;
  padding: 0 10px;
  position: absolute;
  width: 100%;

  p {
    margin-bottom: 0;
    border-bottom: 1px solid #ddd;
    padding: 5px;
    &:hover,
    &:focus {
      background: ${DEFAULT_COLORS.link};
      color: #fff;
      cursor: pointer;
    }
  }
`;

const FlexContainer = css`
  max-width: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
`;

const PersistentMessage = css`
  border-top: 7px solid #182b67;
  padding-top: 20px;
  text-align: center;
  font-size: 28px;
  color: #172b67;
  font-weight: 700;

  &.persistentMessage2 {
    display: none;
    border-top: none;
    font-size: 14px;
    color: #6b6b6b;
    padding-top: 0;
    font-weight: 300;
    margin: -15px auto 0;
    padding: 0 34px;
    @media (max-width: 801px) {
      margin: 5px auto 0;
      padding: 5px 25px;
    }
    .main-app-page-0 & {
      display block

    }
  }
  @media (max-width: 801px) {
    margin-bottom: 0;
  }
`;

const StepOverviewContainer = css`
  display: flex;
  justify-content: space-around;
  align-items: center;
  font-size: 14px;
  // border-top: solid 1px #bab9ba;
  border-bottom: dotted 1px #bab9ba;
  color: #bab9ba;
  font-weight: 700;
  margin-bottom: 10px;
`;

const OverviewDetails = css`
  margin: 15px 0;
  color: ${props => (props.isHighlighted ? '#00486b' : '#bab9ba')};

  span {
    background: ${props => (props.isHighlighted ? '#00486b' : '#bab9ba')};
  }
`;

const OverviewNumber = css`
  background: #bab9ba;
  border-radius: 100%;
  color: #fff;
  display: inline-block;
  height: 22px;
  line-height: 22px;
  margin-right: 3px;
  text-align: center;
  width: 22px;
`;

export default {
  StepOverviewContainer,
  OverviewDetails,
  OverviewNumber,
  PersistentMessage,
  HeaderContainer,
  HeaderImage,
  AppWrapper,
  SurveyBackground,
  SurveyContainer,
  QuestionNumber,
  FormContainer,
  GlobalSealImg,
  ChoiceContainer,
  InputContainer,
  inputStyle,
  InputField,
  StyledTextArea,
  AddressContainer,
  StyledSelect,
  StyledZip,
  FinalInputContainer,
  HiddenContainer,
  QuestionTitle,
  InputTitle,
  Subtitle,
  TipContainer,
  QuestionContainer,
  QuestionBodyContainer,
  StyledSlider,
  StyledThumb,
  DisplayNumber,
  NumberContainer,
  SliderContainer,
  FillContainer,
  ProgressContainer,
  StatusBar,
  FooterNumberStatus,
  SubmitButton,
  BackButton,
  ButtonContainer,
  FooterContainer,
  DisclosureContainer,
  ModalButtonContainer,
  ModalButton,
  ModalBackground,
  ModalContainer,
  ExitButton,
  ModalInformation,
  BoldText,
  Text,
  FooterText,
  ImageList,
  HeadlineContainer,
  HeadlineHeader,
  SubHeader,
  SmallText,
  ErrorText,
  FinalDisclosureContainer,
  SpinnerContainer,
  Spinner,
  LoadingContainer,
  SpinnerMainContainer,
  CongratsHolder,
  CheckboxWrapper,
  CheckboxContainer,
  CheckboxLabel,
  CheckboxParagraph,
  ChangeAddress,
  AddressSubtext,
  LenderDropdown,
  FlexContainer,
};
